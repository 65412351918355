import axios, { AxiosResponse } from 'axios';
import { has, pick } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import * as apiSdk from '@gmm/sdk';
import { ClassDetail } from '@gmm/sdk/classes/show';
import { CoteachersCreateResponse } from '@gmm/sdk/coteachers/create';
import { KlassAssignmentItem } from '@gmm/sdk/shared';
import { useSnackbar } from '~/lib/hooks/useSnackbar';
import { ReadOnlyOptions } from '~/lib/sdk';

import { UseGetClassDetail, useGetClassDetail } from '../useGetClassDetail';
import { useGetClassList } from '../useGetClassList';

const defaultMatch = { params: { classId: undefined } };

const listClassProperties = [
  'active',
  'currentAssignment',
  'generalPractice',
  'id',
  'name',
];

export interface UseClassDetail
  extends Pick<UseGetClassDetail, 'mutate' | 'mutateDraft'> {
  addCoteachers: (teacherIds: string[]) => Promise<void>;
  archive: () => Promise<void>;
  classDetail: UseGetClassDetail['data'];
  classId: string;
  isDefaultAssignment: <A extends Pick<KlassAssignmentItem, 'assignmentId'>>(
    assignment: A,
  ) => boolean;
  isLoading: boolean;
  removeCoteacher: (teacherId: string) => Promise<void>;
  renameClass: (name: string) => Promise<void>;
  setClassGoal: (todaysGoal: number | null) => Promise<void>;
  setDefaultAssignment: <
    A extends Pick<
      KlassAssignmentItem,
      'assignmentId' | 'name' | 'pointsRequired' | 'isDefault'
    >,
  >(
    assignment: A,
    removingDefault: boolean,
    throwError?: boolean,
  ) => Promise<void>;
  setToSpiralReview: () => Promise<void>;
  updateSettings: (setting: Partial<ClassDetail>) => Promise<void>;
}

export const useClassDetail = ({
  readOnly,
}: ReadOnlyOptions = {}): UseClassDetail => {
  const {
    activeClasses,
    isValidating: isLoadingList,
    mutateDraft: mutateDraftList,
  } = useGetClassList({ readOnly: true });
  const {
    params: { classId = '' },
  } = useRouteMatch<{ classId: string }>('/classes/:classId') ?? defaultMatch;
  const { enqueueGenericError, enqueueError, enqueueSuccess } = useSnackbar({
    autoTranslate: true,
  });

  const {
    data: classDetail,
    error,
    mutate,
    mutateDraft,
  } = useGetClassDetail({ readOnly });

  const setToSpiralReview = useCallback(async () => {
    // Already in spiral review
    if (classDetail?.generalPractice) return;

    await mutateDraft(draft => {
      delete draft.currentAssignment;
      draft.generalPractice = true;
    }, false);

    try {
      const body = { klass: { setToSpiralReview: true } };
      const config = apiSdk.classes.update(classId, body);

      await axios.request(config);
    } catch {
      await mutateDraft(() => classDetail, false);
      enqueueGenericError();
    }
  }, [classDetail, classId, enqueueGenericError, mutateDraft]);

  const setDefaultAssignment = useCallback<
    UseClassDetail['setDefaultAssignment']
  >(
    async (assignment, removingDefault, throwError) => {
      // Don't do anything if we're in spiral review and trying to go to that
      if (removingDefault && classDetail?.generalPractice) return;

      // Don't do anything if the assignment is not changing
      if (
        !removingDefault &&
        // check if it was already the default assignment
        assignment.isDefault &&
        assignment.assignmentId === classDetail?.currentAssignment?.id
      ) {
        return;
      }

      await mutateDraft(draft => {
        if (removingDefault) {
          delete draft.currentAssignment;
          draft.generalPractice = true;
        } else {
          draft.currentAssignment = {
            id: assignment.assignmentId,
            name: assignment.name,
            pointsToFinish: assignment.pointsRequired,
            type: 'Assignment',
          };
          draft.generalPractice = false;
        }
      }, false);

      try {
        const config = !removingDefault
          ? apiSdk.classAssignments.create(classId, {
              assignmentId: assignment.assignmentId,
            })
          : apiSdk.classes.update(classId, {
              klass: { clearDefaultAssignment: true },
            });

        await axios.request<unknown, AxiosResponse<never>, any>(config);
      } catch (error) {
        await mutateDraft(() => classDetail, false);
        if (throwError) throw error;
        enqueueError('classAssignments:list.updateDefaultError');
      }
    },
    [classId, classDetail, enqueueError, mutateDraft],
  );

  const archive = useCallback(async () => {
    try {
      const config = apiSdk.classes.destroy(classId);

      mutateDraft(draft => {
        draft.active = false;
      }, false);
      await axios.request(config);
      enqueueSuccess('classItem:archiveSuccess');
    } catch {
      mutateDraft(draft => {
        draft.active = true;
      }, false);
      enqueueGenericError();
    }
  }, [classId, enqueueGenericError, enqueueSuccess, mutateDraft]);

  const updateSettings = useCallback(
    async (setting: Partial<ClassDetail>) => {
      await mutateDraft(draft => {
        Object.assign(draft, setting);
      }, false);

      const body = {
        klass: {
          ...(setting.subjects ? { subjects: setting.subjects } : { setting }),
        },
      };
      const config = apiSdk.classes.update(classId, body);

      try {
        await axios.request(config);
        enqueueSuccess('classSettings:success');
      } catch (error) {
        await mutateDraft(() => classDetail, false);

        if (has(setting, 'enrollmentOpen')) {
          setting.enrollmentOpen === true
            ? enqueueError('app:classLink.enableError')
            : enqueueError('app:classLink.disableError');
        } else {
          enqueueGenericError();
        }
      }
    },
    [
      classDetail,
      classId,
      enqueueError,
      enqueueGenericError,
      enqueueSuccess,
      mutateDraft,
    ],
  );

  const renameClass = useCallback(
    async (name: string) => {
      if (!classDetail) return;

      const newName = name.trim();

      await mutateDraft(draft => {
        draft.name = newName;
      }, false);

      try {
        const body = { name: newName };
        const config = apiSdk.classes.update(classId, body);

        await axios.request(config);
        enqueueSuccess('classItem:renameSuccess');
      } catch {
        await mutateDraft(() => classDetail, false);
        enqueueGenericError();
      }
    },
    [classDetail, classId, enqueueGenericError, enqueueSuccess, mutateDraft],
  );

  const setClassGoal = useCallback(
    async (todaysGoal: number | null) => {
      if (classDetail?.todaysGoal === todaysGoal) return;

      await mutateDraft(draft => {
        draft.todaysGoal = todaysGoal;
      }, false);

      try {
        const body = { goal: todaysGoal };
        const config = apiSdk.dailyGoals.update(classId, body);

        await axios.request(config);
      } catch {
        await mutateDraft(draft => {
          draft.todaysGoal = classDetail?.todaysGoal ?? null;
        }, false);
        enqueueGenericError();
      }
    },
    [classDetail?.todaysGoal, classId, enqueueGenericError, mutateDraft],
  );

  const isDefaultAssignment = useCallback<
    UseClassDetail['isDefaultAssignment']
  >(
    assignment =>
      assignment.assignmentId === classDetail?.currentAssignment?.id,
    [classDetail?.currentAssignment?.id],
  );

  useEffect(() => {
    if (readOnly || !classDetail) return;

    const classItem = pick(classDetail, listClassProperties);

    mutateDraftList(draft => {
      const index = draft.findIndex(({ id }) => id === classItem.id);

      if (index > -1) Object.assign(draft[index], classItem);
    }, false);
  }, [classDetail, mutateDraftList, readOnly]);

  const listClass = activeClasses?.find(({ id }) => id === classId);

  const addCoteachers = useCallback<UseClassDetail['addCoteachers']>(
    async (teacherIds: string[]) => {
      try {
        const data = { teacherIds };
        const config = apiSdk.coteachers.create(classId, data);

        const response = await axios.request<CoteachersCreateResponse>(config);
        const newCoteachers = response.data;

        mutateDraft(draft => {
          draft.coteachers = [...draft.coteachers, ...newCoteachers];
        }, false);
      } catch {
        enqueueGenericError();
      }
    },
    [classId, enqueueGenericError, mutateDraft],
  );

  const removeCoteacher = useCallback<UseClassDetail['removeCoteacher']>(
    async (teacherId: string) => {
      const coteachers = classDetail!.coteachers;

      try {
        mutateDraft(draft => {
          const index = coteachers.findIndex(
            coteacher => coteacher.id === teacherId,
          );

          if (index > -1) draft.coteachers.splice(index, 1);
        }, false);

        const config = apiSdk.coteachers.destroy(classId, teacherId);

        await axios.request(config);
      } catch {
        mutateDraft(draft => {
          draft.coteachers = coteachers;
        }, false);
        enqueueGenericError();
      }
    },
    [classDetail, classId, enqueueGenericError, mutateDraft],
  );

  return useMemo(() => {
    const isLoading = isLoadingList || (!error && !classDetail && !!listClass);

    return {
      addCoteachers,
      archive,
      classDetail,
      classId,
      isDefaultAssignment,
      isLoading,
      mutate,
      mutateDraft,
      removeCoteacher,
      renameClass,
      setClassGoal,
      setDefaultAssignment,
      setToSpiralReview,
      updateSettings,
    };
  }, [
    addCoteachers,
    archive,
    classDetail,
    classId,
    error,
    isDefaultAssignment,
    isLoadingList,
    mutate,
    mutateDraft,
    listClass,
    removeCoteacher,
    renameClass,
    setClassGoal,
    setDefaultAssignment,
    setToSpiralReview,
    updateSettings,
  ]);
};
